import './bootstrap'
import '../css/app.css'

import { createApp, DefineComponent, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { defaultConfig, plugin as formkitPlugin } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { createAutoAnimatePlugin, createAutoHeightTextareaPlugin } from '@formkit/addons'
import { plugin as inertiaPlugin } from 'formkit-addon-inertia'
import { applicationIcons, genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import formkitTailwindTheme from '@/themes/formkit-tailwind'

import AppLayout from '@/layouts/AppLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

import IconPercentage from '~icons/mynaui/percentage-square'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

const pro = createProPlugin('fk-65d32fd12f', inputs)

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const page = await resolvePageComponent(
      `./pages/${name}.vue`,
      import.meta.glob<DefineComponent>('./pages/**/*.vue'),
    )

    const isGuest = name.startsWith('Auth/') || name.startsWith('Welcome')

    const layout = isGuest ? AuthLayout : AppLayout

    page.default.layout = page.default.layout || layout

    return page
  },
  setup({ el, App, props, plugin }) {
    // eslint-disable-next-line vue/component-api-style
    createApp({ render: () => h(App, props) })
      .use(autoAnimatePlugin)
      .use(plugin)
      .use(ZiggyVue)
      .use(
        formkitPlugin,
        defaultConfig({
          config: {
            classes: generateClasses(formkitTailwindTheme),
          },
          icons: {
            ...applicationIcons,
            ...genesisIcons,
            percentage: IconPercentage,
          },
          plugins: [createAutoAnimatePlugin(), createAutoHeightTextareaPlugin, inertiaPlugin, pro],
        }),
      )
      .mount(el)
  },
  progress: {
    color: '#4B5563',
  },
}).then()

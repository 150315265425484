<script lang="ts" setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import ApplicationMark from '@/components/ApplicationMark.vue'
import IconBars3 from '~icons/heroicons/bars-3'
import IconBell from '~icons/heroicons/bell'
import IconBookOpen from '~icons/heroicons/book-open'
import IconCog6Tooth from '~icons/heroicons/cog-6-tooth'
import IconCreditCard from '~icons/heroicons/credit-card'
import IconHandshake from '~icons/ph/handshake'
import IconMagnifyingGlass from '~icons/heroicons/magnifying-glass'
import IconChartPie from '~icons/heroicons/chart-pie'
import IconRectangleGroup from '~icons/heroicons/rectangle-group'
import IconUsers from '~icons/heroicons/users'
import IconXMark from '~icons/heroicons/x-mark'
import route from 'ziggy-js'

const isRoute = (routeName: string) => {
  return route().current(routeName)
}

const page = usePage()

const user = computed(() => page.props.auth.user)

const navigation = [
  {
    name: 'Dashboard',
    href: route('dashboard'),
    icon: IconRectangleGroup,
    routeName: 'dashboard',
    show: true,
  },
  {
    name: 'Bank accounts',
    href: route('bank-account.index'),
    icon: IconCreditCard,
    routeName: 'bank-account.*',
    show: user.value.menu_permissions.show_bank_accounts_link,
  },
  {
    name: 'Borrowers',
    href: route('borrower.index'),
    icon: IconHandshake,
    routeName: 'borrower.*',
    show: user.value.menu_permissions.show_borrowers_link,
  },
  {
    name: 'Investors',
    href: route('investor.index'),
    icon: IconChartPie,
    routeName: 'investor.*',
    show: user.value.menu_permissions.show_investors_link,
  },
  {
    name: 'Loan books',
    href: route('loan-book.index'),
    icon: IconBookOpen,
    routeName: 'loan-book.*',
    show: user.value.menu_permissions.show_loan_books_link,
  },
  {
    name: 'User management',
    href: route('teams.show', 1),
    icon: IconUsers,
    routeName: 'teams.*',
    show: user.value.menu_permissions.show_user_management_link,
  },
]

const sidebarOpen = ref(false)
</script>

<template>
  <TransitionRoot
    :show="sidebarOpen"
    as="template">
    <Dialog
      as="div"
      class="relative z-50 lg:hidden"
      @close="sidebarOpen = false">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full">
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0">
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button
                  class="-m-2.5 p-2.5"
                  type="button"
                  @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <IconXMark
                    aria-hidden="true"
                    class="h-6 w-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
              <div class="flex h-16 shrink-0 items-center">
                <ApplicationMark />
              </div>
              <nav
                :key="route().current()"
                class="flex flex-1 flex-col">
                <ul
                  class="flex flex-1 flex-col gap-y-7"
                  role="list">
                  <li>
                    <ul
                      class="-mx-2 space-y-1"
                      role="list">
                      <template v-for="item in navigation">
                        <li
                          v-if="item.show"
                          :key="item.name">
                          <Link
                            :class="[
                              isRoute(item.routeName)
                                ? 'bg-gray-50 text-crede-blue'
                                : 'text-gray-700 hover:text-crede-blue',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]"
                            :href="item.href">
                            <component
                              :is="item.icon"
                              :class="[
                                isRoute(item.routeName)
                                  ? 'text-crede-blue'
                                  : 'text-gray-400 group-hover:text-crede-blue hover:bg-gray-50',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true" />
                            {{ item.name }}
                          </Link>
                        </li>
                      </template>
                    </ul>
                  </li>

                  <li
                    v-if="user.menu_permissions.show_settings_link"
                    class="mt-auto">
                    <Link
                      :class="[
                        isRoute('settings.index')
                          ? 'text-crede-blue'
                          : 'text-gray-400 hover:text-crede-blue hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                      :href="route('settings.index')">
                      <IconCog6Tooth
                        :class="[
                          isRoute('settings.index') ? 'text-crede-blue' : 'text-gray-400 group-hover:text-crede-blue',
                          'h-6 w-6 shrink-0',
                        ]"
                        aria-hidden="true" />
                      Settings
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center">
        <ApplicationMark />
      </div>
      <nav
        :key="route().current()"
        class="flex flex-1 flex-col">
        <ul
          class="flex flex-1 flex-col gap-y-7"
          role="list">
          <li>
            <ul
              class="-mx-2 space-y-1"
              role="list">
              <template v-for="item in navigation">
                <li
                  v-if="item.show"
                  :key="item.name">
                  <Link
                    :class="[
                      isRoute(item.routeName)
                        ? 'text-crede-blue'
                        : 'text-gray-400 hover:text-crede-blue hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                    :href="item.href">
                    <component
                      :is="item.icon"
                      :class="[
                        isRoute(item.routeName) ? 'text-crede-blue' : 'text-gray-400 group-hover:text-crede-blue',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true" />
                    {{ item.name }}
                  </Link>
                </li>
              </template>
            </ul>
          </li>

          <li
            v-if="user.menu_permissions.show_settings_link"
            class="mt-auto">
            <Link
              :class="[
                isRoute('settings.index') ? 'text-crede-blue' : 'text-gray-400 hover:text-crede-blue hover:bg-gray-50',
                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
              ]"
              :href="route('settings.index')">
              <IconCog6Tooth
                :class="[
                  isRoute('settings.index') ? 'text-crede-blue' : 'text-gray-400 group-hover:text-crede-blue',
                  'h-6 w-6 shrink-0',
                ]"
                aria-hidden="true" />
              Settings
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="lg:pl-72">
    <div
      class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        type="button"
        @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <IconBars3
          aria-hidden="true"
          class="h-6 w-6" />
      </button>

      <!-- Separator -->
      <div
        aria-hidden="true"
        class="h-6 w-px bg-gray-200 lg:hidden" />

      <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form
          action="#"
          class="relative flex flex-1"
          method="GET">
          <label
            class="sr-only"
            for="search-field"
            >Search</label
          >
          <IconMagnifyingGlass
            aria-hidden="true"
            class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" />
          <input
            id="search-field"
            class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            name="search"
            placeholder="Search..."
            type="search" />
        </form>
        <div class="flex items-center gap-x-4 lg:gap-x-6">
          <button
            class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            type="button">
            <span class="sr-only">View notifications</span>
            <IconBell
              aria-hidden="true"
              class="h-6 w-6" />
          </button>

          <!-- Separator -->
          <div
            aria-hidden="true"
            class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />

          <!-- Profile dropdown -->
          <Menu
            as="div"
            class="relative">
            <MenuButton class="-m-1.5 flex items-center p-1.5">
              <span class="sr-only">Open user menu</span>
              <img
                :alt="$page.props.auth.user.name"
                :src="$page.props.auth.user.profile_photo_url"
                class="h-8 w-8 rounded-full object-cover" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white px-4 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <!-- Account Management -->
                <div class="block text-xs text-gray-400">Manage Account</div>

                <MenuItem
                  v-slot="{ active, close }"
                  class="mt-2.5 mb-2">
                  <Link
                    :class="[active ? 'bg-gray-50' : '', 'block py-1.5 text-sm leading-6 text-gray-900']"
                    :href="route('profile.show')"
                    @click="close"
                    >Your profile
                  </Link>
                </MenuItem>

                <MenuItem
                  v-if="$page.props.jetstream.hasApiFeatures"
                  v-slot="{ active, close }"
                  class="mt-2.5 mb-2">
                  <Link
                    :class="[active ? 'bg-gray-50' : '', 'block py-1.5 text-sm leading-6 text-gray-900']"
                    :href="route('api-tokens.index')"
                    @click="close"
                    >API Tokens
                  </Link>
                </MenuItem>

                <div class="border-t border-gray-200 dark:border-gray-600" />

                <MenuItem
                  v-slot="{ active, close }"
                  class="mt-2">
                  <Link
                    :class="[active ? 'bg-gray-50' : '', 'py-1.5 w-full text-left text-sm']"
                    :href="route('logout')"
                    as="button"
                    method="post"
                    type="button"
                    @click="close"
                    >Logout
                  </Link>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <main class="py-5">
      <div class="sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>
  </div>
</template>
